<template>
  <div
    class=""
    id="navbar"
    @mouseover="persistHomeNav = true"
    @mouseleave="persistHomeNav = false"
    :class="[{ updateNavStyle : persistHomeNav}]"
  >
    <b-navbar toggleable="xl" class="p-0" variant="">
      <b-container class="max-width align-items-xl-around align-items-end position-relative pb-md-3">
        <b-navbar-brand to="/" 
          class="navbar-dark mr-0 ml-3 border-0 navbar-logo" 
          exact 
          :class="[{ showNavItem : persistHomeNav}]">
          <nuxt-img provider="static" format="webp"
            src="/img/1063Guidelines-Logo.png"
            alt="Guidelines Advertising"
            class="w-100"
            width="200"
            height="59"
            sizes="lg:200px"
          />
        </b-navbar-brand>
        <button id="menu-btn" 
          @click="toggleNavigation" 
          :class="[{ showNavItem : persistHomeNav}]">
          <div class="bar1" :class="{active:navOpen}"></div>
          <div class="bar2" :class="{active:navOpen}"></div>
          <div class="bar3" :class="{active:navOpen}"></div>
        </button>
      </b-container>
      <nav class="navigation-menu">
        <div class="nav-bg one"></div>
        <div class="nav-bg two"></div>
        <b-container fluid class="h-100 position-relative">
          <div class="barlow-semibold d-flex align-items-end nav-text">
            <div class="large-nav-text-container">
              <p class="guidelines-text gotham-medium h5">
                <nuxt-link @click.native="toggleNavigation" to="/" class="d-inline-block text-black" >1063_GUIDELINES</nuxt-link>
              </p>
              <div class="large-nav-text">
                <img src="/img/guidelines-nav-header.png" alt="Nav" class="w-100">
              </div>
            </div>
            <div class="ga-stamp-white">
              <img src="/img/guidelines-circular-stamp-white.png" alt="" class="w-100">
            </div>
          </div>
          <b-container>
            <b-row class="h-100 w-100">
              <b-col md="7" class="w-100">
              </b-col>
              <b-col md="5" class="nav-wrapper w-100">
                <div>
                  <div class="nav-inner">
                    <ul>
                      <li class="ga-nav-item barlow-semibold ">
                        <nuxt-link @click.native="toggleNavigation" to="/about" class="ga-nav-link"
                        >About</nuxt-link>
                      </li>
                      <li class="ga-nav-item barlow-semibold ">
                        <nuxt-link @click.native="toggleNavigation" to="/portfolio" class="ga-nav-link" >Portfolio</nuxt-link>
                      </li>
                      <li class="ga-nav-item barlow-semibold ">
                        <nuxt-link @click.native="toggleNavigation" to="/interior-design" class="ga-nav-link" >Interior Design</nuxt-link>
                      </li>
                      <li class="ga-nav-item barlow-semibold ">
                        <nuxt-link @click.native="toggleNavigation" to="/renderings" class="ga-nav-link" >Renderings</nuxt-link>
                      </li>
                      <li class="ga-nav-item barlow-semibold ">
                        <nuxt-link @click.native="toggleNavigation" to="/culture" class="ga-nav-link" >Culture</nuxt-link>
                      </li>
                      <li class="ga-nav-item barlow-semibold ">
                        <nuxt-link @click.native="toggleNavigation" to="/contact" class="ga-nav-link" >Contact</nuxt-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-container>
      </nav>
    </b-navbar>
  </div>
</template>
<script>
import { gsap } from "gsap";

export default {
  data() {
    return {
      sticky: false,
      navbar: null,
      refreshed: true,
      persistHomeNav: false,
      navOpen: false,
      navAnimation: null,
      menuTween: null,
      submenuOpen: false
    };
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    this.navbar = document.getElementById("navbar").offsetTop;
    window.addEventListener('scroll', this.updateScroll);
    this.navAnimation = gsap.timeline({ defaults: { duration: 0.4, ease: 'expo.inOut' }, reversed: true})
      .to('.navigation-menu', { top: '0%', duration: 0.2 })
      .to('.one', {
        width: '100%',
        ease: 'expo.inOut',
      })
      .to('.two', {
        width: '100%',
        ease: 'expo.inOut',
        delay: -0.25
      })
      .fromTo('.nav-inner', 
        {
          right: '-100%',
          ease: 'expo.in'
        },
        {
          right: '0%',
          ease: 'expo.out',
          delay: -0.25
        }
      )
      .fromTo('.guidelines-text', 
        {
          top: '-100%',
          ease: 'expo.in'
        },
        {
          top: '100px',
          ease: 'expo.out',
          duration: 0.2,
        }
      )
      .fromTo('.large-nav-text', 
        {
          top: '-100%',
          ease: 'expo.in'
        },
        {
          top: '150px',
          ease: 'expo.out',
          duration: 0.3
        }
      )
      .fromTo('.ga-nav-item', 
        { x: '10%', opacity: 0 },
        {
          x: '0%',
          opacity: 1,
          duration: 0.2,
          ease: 'expo.inOut',
          stagger: 0.1
        },
      )
      .fromTo('.ga-stamp-white', 
        {
          opacity: 0,
          scale: 5,
          ease: 'expo.in',
          display: 'none'
        },
        {
          opacity: 1,
          scale: 1,
          ease: 'expo.out',
          zIndex: 5,
          display: 'block'
        },
      )
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;

      if(window.innerWidth < 1199) {
        this.persistHomeNav = true;
        console.log('###window smaller than 1199px###')
      } 
    },
    moveToSection(to) {
      const body = document.body;
      const element = document.getElementById(to);
      scrollTo({ top: element.offsetTop - 100, behavior: "smooth" });
    },
    toggleNavigation() {
      this.navOpen = !this.navOpen;
      console.log('nav open?: ' + this.navOpen);
      if(this.navOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }

      this.navAnimation.reversed() ? this.navAnimation.timeScale(1.5).play() : this.navAnimation.timeScale(1.5).reverse();
    },
  }
};
</script>
<style lang="scss" scoped>
@import "~/assets/styles/variables";

#navbar {
  z-index: 30;
  transition: all 0.15s ease;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 15px 15px 10px;

  @media screen and (max-width: 1199px) {
    // background-color: #fff;
  }

  &.border-none {
  }

  .navbar-logo {
    visibility: hidden;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }

  .navigation-menu {
    position: fixed;
    top: -100%;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;

    .nav-text {
    }

    .large-text {
      font-size: 12rem;
    }

    .ga-stamp-white {
      position: absolute;
      max-width: 380px;
      left: -111px;
      top: 256px;

      @media screen and (max-width: 1199px) {
        max-width: 240px;
        left: -60px;
        top: 180px;
      }

      @media screen and (max-width: 480px) {
        top: unset;
        left: -80px;
        bottom: -100px;
      }
    }

    .large-nav-text-container {
      
      .guidelines-text {
        position: absolute;
        top: 0;
        left: 100px;
        letter-spacing: 4px;
        line-height: 150%;

        @media screen and (max-width: 480px) {
          left: 15px;
        }
      }

      .large-nav-text {
        position: absolute;
        top: 0;
        left: 96px;
        width: 100%;
        max-width: 500px;


        @media screen and (max-width: 640px) {
          max-width: 320px;
        }

        @media screen and (max-width: 480px) {
          left: 15px;
          max-width: 180px;
        }

      }
    }

    .nav-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    .one {
      width: 4px;
      background-color: #000;
    }

    .two {
      width: 0;
      background-color: #ff40b3;
    }

    .nav-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      

      .nav-inner {
        position: relative;
        z-index: 10;

        @media screen and (max-width: 640px) {
          margin-top: 100px;
        }

        li {
          list-style-type: none;
        }

        .ga-nav-item {

        }

        .ga-nav-link {
          font-size: 2.25rem;
          color: #000;

          @media screen and (max-width: 640px) {
            font-size: 1.75rem;
          }

          @media screen and (max-width: 480px) {
            font-size: 1.4rem;
          }
        }

        .nav-submenu {
          max-height: 0;
          opacity: 0;
          transition: max-height 0.25s ease-in-out;
          text-align: left;

          li {
              list-style-type: none;
              margin: 2px 0;
          }
        }

        .submenu-link {
          font-size: 16px;
        }

        .open-submenu {
          max-height: 600px;
          opacity: 1;
        }
      }
    }
  }
  .navbar-toggler {
    border: 0;
  }

  .navbar-brand.nuxt-link-active {
    &::after {
      content: '';
      width: 40px;
      background: unset;
    }
  }

  .nuxt-link-active {
    color: #000 !important;
    // font-weight: 700 !important;

    &::after {
      // content: '';
      // width: 100%;
      // position: absolute;
      // height: 2px;
      // border-radius: 5px;
      // background: #ff56b1;
      // bottom: 50%;
      // left: 0;
      // transform-origin: left;
      // transition: transform .5s ease;
      // transform: scaleX(1);

      // @media screen and (max-width: 1199px) {
      //   width: 140px;
      // }
    }
  }
}

#menu-btn {
  color: #fff;
  overflow: hidden;
  padding: 7px 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 0;
  z-index: 11;
  background: transparent;

  visibility: hidden;
  transition: 0.3s ease-in-out;
  opacity: 0;
  
  .bar1 {
    width: 24px;
    height: 2px;
    margin-bottom: 2px;
    transition: transform 0.3s;
    transform-origin: top right;
    border: 2.5px solid #000;
    border-radius: 5px;
      background-color: #000;
    &.active {
      transform: rotate(-45deg);
      transform-origin: top right;
      margin-bottom: 6px;
    }
    @media screen and (max-width: 1199px) {
      background-color: #000;
    }
  }
  .bar2 {
    width: 32px;
    height: 2px;
    margin-bottom: 2px;
    transition: transform 0.3s;
    transform-origin: top right;
    border: 2.5px solid #000;
    border-radius: 5px;
    background-color: #000;
    &.active {
      opacity: 0;
      transform: translateX(500px);
    }
    @media screen and (max-width: 1199px) {
      background-color: #000;
    }
  }
  .bar3 {
    width: 24px;
    height: 2px;
    opacity: 1;
    transition: transform 0.3s;
    transform-origin: top right;
    border: 2.5px solid #000;
    border-radius: 5px;
      background-color: #000;
    &.active {
      transform: rotate(45deg);
      transform-origin: bottom right;
    }

    @media screen and (max-width: 1199px) {
      background-color: #000;
    }
  }
}

.updateNavStyle {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 3px 3px 3px rgb(213, 213, 213);
}

.showNavItem {
  visibility: visible !important;
  opacity: 1 !important;
  transition: 0.3s ease-in-out !important;
}

</style>
